<template>
  <div class="adaptive-width mar-top-60">
    <div class="help">
      <div class="help-left">
        <div class="help-menu">
          <el-menu
            :default-active="defaultActive"
            :unique-opened="true"
            @select="selectMenu"
            class="el-menu-vertical-demo"
          >
            <div v-for="(menu, menuIndx) in helpCenterMenu" :key="menuIndx">
              <el-submenu v-if="menu.children.length > 0" :index="menu.index">
                <template slot="title">
                  <span>{{ menu.title}}</span>
                </template>
                <div v-for="(item, itemIndex) in menu.children" :key="itemIndex">
                  <el-submenu v-if="item.children.length > 0" :index="item.index">
                    <template slot="title">{{ item.title}}</template>
                    <el-menu-item
                      v-for="item1 in item.children"
                      :index="item1.index"
                      :key="item1.index"
                    >{{ item1.title}}</el-menu-item>
                  </el-submenu>
                  <el-menu-item v-else :index="item.index">{{ item.title}}</el-menu-item>
                </div>
              </el-submenu>
              <el-menu-item v-else :index="menu.index">
                <span slot="title">{{ menu.title }}</span>
              </el-menu-item>
            </div>
          </el-menu>
        </div>
      </div>
      <div class="help-right">
        <div class="help-header">
          <!-- <div>{{ $t('商家端入驻流程')}}</div> -->
          <img class="help-logo" src="@/assets/image/help_center/sc_logo.webp" alt />
          <div class="help-image" v-for="(menu, menuIndx) in helpCenterMenu" :key="menuIndx">
            <div style="margin-bottom: 20px">{{menu.title}}</div>
            <div v-if="menu.children.length > 0" style="position:relative">
              <div
                v-for="(item, itemIndex) in menu.children"
                :key="itemIndex"
                style="position: relative;"
              >
                <div v-if="item.children.length > 0">
                  <div
                    v-for="(item1, index1) in item.children"
                    :key="index1"
                    style="position: relative;"
                  >
                    <div :id="item1.index" :style="{'position': 'absolute','top': '-200px'}" />
                    <img
                      :src="require(`@/assets/image/help_center/${languagePath}/${item1.index}`)"
                      alt
                    />
                  </div>
                </div>
                <div v-else>
                  <div :id="item.index" :style="{'position': 'absolute','top': '-200px'}" />
                  <img
                    :src="require(`@/assets/image/help_center/${languagePath}/${item.index}`)"
                    alt
                  />
                </div>
              </div>
            </div>
            <div v-else style="position:relative">
              <div :id="menu.index" :style="{'position': 'absolute','top': '-200px'}" />
              <img :src="require(`@/assets/image/help_center/${languagePath}/${menu.index}`)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      helpCenterMenu: [
        {
          index: 'seller.jpg',
          title: this.$t('商家端入驻流程'),
          children: [],
        },
        {
          index: 'seller.jpg',
          title: this.$t('商家端操作手册'),
          children: [
            {
              index: '1-1.png',
              title: this.$t('基本信息'),
              children: [
                {
                  index: '1-1.png',
                  title: this.$t('我的账号'),
                },
                {
                  index: '1-2.png',
                  title: this.$t('基础设置'),
                },
                {
                  index: '1-3.png',
                  title: this.$t('添加商品'),
                },
                {
                  index: '1-4.png',
                  title: this.$t('店铺装修'),
                },
                {
                  index: '1-5.png',
                  title: this.$t('订单管理'),
                },
                {
                  index: '1-7.png',
                  title: this.$t('发货流程'),
                },
                {
                  index: '1-6.png',
                  title: this.$t('退款/退货流程'),
                },
                {
                  index: '1-8.png',
                  title: this.$t('我的钱包'),
                },
              ],
            },
            {
              index: '2-1.png',
              title: this.$t('店铺营销'),
              children: [
                {
                  index: '2-1.png',
                  title: this.$t('营销活动'),
                },
                {
                  index: '2-2.png',
                  title: this.$t('营销工具'),
                },
              ],
            },
            {
              index: '3.png',
              title: this.$t('Show管理'),
              children: [],
            },
            {
              index: '4-1.png',
              title: this.$t('直播管理'),
              children: [
                {
                  index: '4-1.png',
                  title: this.$t('直播管理'),
                },
                {
                  index: '4-2.png',
                  title: this.$t('商家端App登录'),
                },
                {
                  index: '4-3.png',
                  title: this.$t('商家端App直播'),
                },
              ],
            },
          ],
        },
      ],
      languagePath: this.$i18n.locale || 'en_US',
      defaultActive: 'seller.jpg',
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.scrollTo(0, 0)
  },
  beforeDestroy() {
    // 组件销毁时，销毁监听事件
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.setDefault(this.helpCenterMenu)
    },
    setDefault(children) {
      if (children.length > 0) {
        children.forEach((item) => {
          if (item.children && item.children.length > 0) {
            this.setDefault(item.children)
          } else {
            if (
              document.getElementById(item.index).getBoundingClientRect().top > 0 &&
              document.getElementById(item.index).getBoundingClientRect().top < 500
            ) {
              this.defaultActive = item.index
            }
          }
        })
      }
    },
    selectMenu(index) {
      console.log('index', index)
      // document.getElementById(index).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      document.getElementById(index).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    },
  },
}
</script>

<style scoped>
.help {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.help-left {
  width: 200px;
  margin-right: 50px;
}

.help-menu {
  position: sticky;
  top: 200px;
}

.help-right {
  flex: 1;
  min-width: 700px;
  max-width: 1200px;
  min-height: 600px;
}

.help-header {
  padding: 20px 0;
  font-size: 24px;
  font-weight: 800;
  color: #192537;
}

.help-logo {
  width: 200px !important;
  margin-top: 50px;
}

.help-image img {
  width: 100%;
  display: block;
}
</style>